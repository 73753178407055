<template>
  <span class="tooltip-wrapper" :class="classes" @mouseenter.self="onMouseEnter" @mouseleave.self="onMouseLeave" @focusin="onMouseEnter" @focusout="onMouseLeave">
    <slot @mouseenter.prevent.stop @mouseleave.prevent.stop/>
    <div v-if="viewTooltip" ref="tooltipMessage" class="tooltip-message" :style="{ bottom: bottom + 'px', left: left ? left + '%' : '' }" v-text="message"/>
  </span>
</template>

<script lang="ts">
import {defineComponent, nextTick, ref, toRefs, watch} from 'vue';

  interface Props {
    message: string;
  }

export default defineComponent({
  props: {
    message: {
      default: ""
    },
    enabled: {
      default: true
    },
    bottom: {
      default: null
    },
    left: {
      default: null
    },
    classes: {
      default: null
    },
    sidebarUse: {
      default: false
    }
  },
  setup(props: any, ctx) {
    const {message, enabled, bottom, left, classes, sidebarUse} = toRefs(props);
    const viewTooltip = ref(false)
    const tooltipMessage = ref<HTMLDivElement>()

    const show = () => {
      if(message.value) viewTooltip.value = true
    }

    watch(enabled, (val) => {
      if(!val) {
        viewTooltip.value = false;
      }
    })

    const onMouseEnter = () => {
      if(!enabled.value) return;

      window.addEventListener('click', () => {
        viewTooltip.value = false;
      }, {once: true})

      show()
      nextTick(() => {
        if(tooltipMessage.value == null) return;

        /** get tooltip size and position */
        const sidebarElement = document.querySelector('#sidebar-wrap')
        if (!sidebarElement) return

        const rect = tooltipMessage.value!.getBoundingClientRect()
        const sidebarRect = sidebarElement.getBoundingClientRect()

        /** Check if out of window or over sidebar */
        if(rect.x + rect.width > window.innerWidth) {
          const overSize = window.innerWidth - (rect.x + rect.width)
          tooltipMessage.value!.style.left = `${overSize + 16}px`
        }

        if(rect.x < sidebarRect.width) {
          if (sidebarUse.value) return false
          tooltipMessage.value!.style.left = `${rect.width / 2}px`
        }

        if(rect.y + rect.height > window.innerHeight) {
          const overSize = window.innerHeight  - (rect.y - rect.height)
          tooltipMessage.value!.style.bottom = `${overSize}px`
        }

        if(rect.y < 0) {
          tooltipMessage.value!.style.bottom = `${Math.abs(rect.x) + 100}px`
        }
      })

    }
    const onMouseLeave = () => {
      viewTooltip.value = false;
    }

    return {
      onMouseEnter,
      onMouseLeave,
      viewTooltip,
      message,
      sidebarUse,
      tooltipMessage
    }
  }
})
</script>

<style lang="scss" scoped>
  .tooltip-wrapper {
    position: relative;

    .tooltip-message {
      position: absolute;
      bottom: -35px;
      background-color: black;
      color: #fff;
      padding: 6px 10px;
      border-radius: 4px;
      white-space: nowrap;
      font-size: 14px;
      z-index: 495;
      transform: translateX(-50%);
      left: 50%;
    }
  }
</style>
